// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

import { library, dom } from "@fortawesome/fontawesome-svg-core";

import { faAngleDown          as fasFaAngleDown          } from "@fortawesome/pro-solid-svg-icons/faAngleDown";
import { faAngleLeft          as fasFaAngleLeft          } from "@fortawesome/pro-solid-svg-icons/faAngleLeft";
import { faAngleRight         as fasFaAngleRight         } from "@fortawesome/pro-solid-svg-icons/faAngleRight";
import { faAngleUp            as fasFaAngleUp            } from "@fortawesome/pro-solid-svg-icons/faAngleUp";
import { faBars               as fasFaBars               } from "@fortawesome/pro-solid-svg-icons/faBars";
import { faClock              as fasFaClock              } from "@fortawesome/pro-solid-svg-icons/faClock";
import { faComment            as fasFaComment            } from "@fortawesome/pro-solid-svg-icons/faComment";
import { faEnvelope           as fasFaEnvelope           } from "@fortawesome/pro-solid-svg-icons/faEnvelope";
import { faFolder             as fasFaFolder             } from "@fortawesome/pro-solid-svg-icons/faFolder";
import { faMapMarker          as fasFaMapMarker          } from "@fortawesome/pro-solid-svg-icons/faMapMarker";
import { faMinus              as fasFaMinus              } from "@fortawesome/pro-solid-svg-icons/faMinus";
import { faPhone              as fasFaPhone              } from "@fortawesome/pro-solid-svg-icons/faPhone";
import { faPlus               as fasFaPlus               } from "@fortawesome/pro-solid-svg-icons/faPlus";
import { faPrint              as fasFaPrint              } from "@fortawesome/pro-solid-svg-icons/faPrint";
import { faQuestionCircle     as fasFaQuestionCircle     } from "@fortawesome/pro-solid-svg-icons/faQuestionCircle";
import { faSearch             as fasFaSearch             } from "@fortawesome/pro-solid-svg-icons/faSearch";
import { faTag                as fasFaTag                } from "@fortawesome/pro-solid-svg-icons/faTag";
import { faTimes              as fasFaTimes              } from "@fortawesome/pro-solid-svg-icons/faTimes";
import { faUserCircle         as fasFaUserCircle         } from "@fortawesome/pro-solid-svg-icons/faUserCircle";

import { faBasketballHoop     as fasFaBasketballHoop     } from "@fortawesome/pro-regular-svg-icons/faBasketballHoop";
import { faBook               as farFaBook               } from "@fortawesome/pro-regular-svg-icons/faBook";
import { faClipboardListCheck as farFaClipboardListCheck } from "@fortawesome/pro-regular-svg-icons/faClipboardListCheck";
import { faDumbbell           as fasFaDumbbell           } from "@fortawesome/pro-regular-svg-icons/faDumbbell";
import { faIdBadge            as farFaIdBadge            } from "@fortawesome/pro-regular-svg-icons/faIdBadge";
import { faMapMarkedAlt       as farFaMapMarkedAlt       } from "@fortawesome/pro-regular-svg-icons/faMapMarkedAlt";
import { faWhistle            as farFaWhistle            } from "@fortawesome/pro-regular-svg-icons/faWhistle";

import { faFacebookF          as fabFaFacebookF          } from "@fortawesome/free-brands-svg-icons/faFacebookF";
import { faInstagram          as fabFaInstagram          } from "@fortawesome/free-brands-svg-icons/faInstagram";
import { faTwitter            as fabFaTwitter            } from "@fortawesome/free-brands-svg-icons/faTwitter";

/**
 * Add solid icons
 */
library.add(fasFaAngleDown, fasFaAngleLeft, fasFaAngleRight, fasFaAngleUp, fasFaBars, fasFaClock, fasFaComment, fasFaEnvelope, fasFaFolder, fasFaMapMarker, fasFaMinus, fasFaPhone, fasFaPlus, fasFaPrint, fasFaQuestionCircle, fasFaSearch, fasFaTag, fasFaTimes, fasFaUserCircle);

/**
 * Add regular icons
 */
library.add(fasFaBasketballHoop, farFaBook, farFaClipboardListCheck, fasFaDumbbell, farFaIdBadge, farFaMapMarkedAlt, farFaWhistle);

/**
 * Add brand icons
 */
library.add(fabFaFacebookF, fabFaInstagram, fabFaTwitter);

/**
 * Watch the DOM to insert icons
 */
dom.watch();
